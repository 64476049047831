@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
@font-face {
	font-family: 'calpslight';
	src: url(/static/media/typesketchbook_-_calps-light-webfont.0f8b2280.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-light-webfont.3488fa37.woff)
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'zoojaregular';
	src: url(/static/media/aerotype_-_zooja-webfont.86f2d727.woff2) format('woff2'),
		url(/static/media/aerotype_-_zooja-webfont.18aea9fa.woff) format('woff');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'calpsmedium';
	src: url(/static/media/typesketchbook_-_calps-medium-webfont.0bde45fd.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-medium-webfont.3380d018.woff)
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'calpsbold';
	src: url(/static/media/typesketchbook_-_calps-bold-webfont.3eb60ac8.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-bold-webfont.3c5dd8b1.woff)
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url(/static/media/typesketchbook_-_calps-bold-webfont.3eb60ac8.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-bold-webfont.3c5dd8b1.woff)
			format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url(/static/media/typesketchbook_-_calps-medium-webfont.0bde45fd.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-medium-webfont.3380d018.woff)
			format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url(/static/media/typesketchbook_-_calps-regular-webfont.9485e09b.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-regular-webfont.f87cf19a.woff)
			format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url(/static/media/typesketchbook_-_calps-semilight-webfont.f0299b6e.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-semilight-webfont.c7b7ca2e.woff)
			format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url(/static/media/typesketchbook_-_calps-semilight-italic-webfont.572602c3.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-semilight-italic-webfont.5b4f971e.woff)
			format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Calps';
	src: url(/static/media/typesketchbook_-_calps-light-webfont.0f8b2280.woff2)
			format('woff2'),
		url(/static/media/typesketchbook_-_calps-light-webfont.3488fa37.woff)
			format('woff');
	font-weight: 300;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

@font-face {
	font-family: 'calpslight';
	src: url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'zoojaregular';
	src: url('./assets/fonts/aerotype_-_zooja-webfont.woff2') format('woff2'),
		url('./assets/fonts/aerotype_-_zooja-webfont.woff') format('woff');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'calpsmedium';
	src: url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'calpsbold';
	src: url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff')
			format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff')
			format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-regular-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-regular-webfont.woff')
			format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-semilight-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-semilight-webfont.woff')
			format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-semilight-italic-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-semilight-italic-webfont.woff')
			format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff')
			format('woff');
	font-weight: 300;
	font-style: normal;
}